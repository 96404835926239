export const environment = {
  production: true,
  environment: 'production',
  appUrl: 'https://app.tiendacrypto.com/',
  apiUrl: 'https://alpha.tiendadolar.com.ar/api/v2/',
  tcApiUrl: 'https://api.tiendacrypto.com/v1/',
  apiManteca: 'https://api.manteca.dev/crypto/v1/',
  socketUrl: 'https://sockets.tiendacrypto.com',
  captchaKey: '6Le5naIfAAAAAHXINLK51INzAiW6Ipo7FMNgJwuq',
  accumulatorWalletOP: '0x0ED78ae2a0800D6395Cf9e311659323BF2822C56',
  accumulatorWalletCHLAndCOL: '0x5A13ec194649B4C416996F4A46dFfD649fAf404f',
  tcUrl: 'https://app.tiendacrypto.com/',
};
