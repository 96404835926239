import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthSocketService {
  _socket: Socket;
  _emailSocket: Socket;

  get socket() {
    return this._socket;
  }

  get emailSocket() {
    return this._emailSocket;
  }

  constructor() {}

  connect() {
    if (this._socket) {
      return;
    }
    this._socket = io(environment.socketUrl, {
      auth: { token: localStorage.getItem('JWT_TOKEN'), admin: false },
    });
  }

  disconnect() {
    if (this._socket) {
      this._socket.disconnect();
      this._socket = null;
    }
  }

  connectEmailSocket(email: string) {
    this._emailSocket = io(`${environment.socketUrl}/spot`, {
      auth: { id: email },
    });
  }

  disconnectEmailSocket() {
    if (this._emailSocket) {
      this._emailSocket.disconnect();
    }
  }
}
