import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private router: Router, private metaService: Meta) {}

  createCanonicalUrl() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url.slice(1);
        if (url === 'core') url = '';
        this.metaService.removeTag('rel="canonical"');
        const canonicalUrl = `${environment.appUrl}${url}`;
        this.metaService.updateTag({ rel: 'canonical', href: canonicalUrl });
      }
    });
  }
}
