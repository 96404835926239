import { BrowserModule, Meta } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import * as Sentry from '@sentry/angular-ivy';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import localeFr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { NativeDateModule } from '@angular/material/core';
registerLocaleData(localeFr);

import { SharedModule } from './modules/shared/shared.module';
import { LazyLoadDirective } from './utils/directives/lazy-load-directive.directive';
import { IConfig } from 'ngx-mask';
import { TokenInterceptor } from './pages/auth/utils/token.interceptor';
import { Router } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { provideLottieOptions } from 'ngx-lottie';

export function initializeApp(http: HttpClient) {
  return () => {
    return new Promise<void>((resolve) => {
      http
        .get('assets/animations/dashboard-loader/load-manteca.json')
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  };
}

@NgModule({
  declarations: [AppComponent, LazyLoadDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSortModule,
    MatExpansionModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    SharedModule,
    NativeDateModule,
    RecaptchaV3Module,
  ],
  exports: [],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.captchaKey,
    },
    Meta,
    { provide: LOCALE_ID, useValue: 'es-AR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient],
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
