import { environment } from 'src/environments/environment';
import { IBlockchainStatus, IFiatStatus } from '../models/Crypto';
import {
  ACCOUNT_CURRENCY,
  DEVICE_TYPE,
  DOCUMENT_TYPE,
  IComplianceChecks,
  IUserInfoField,
  IUserPepData,
} from '../models/User';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { User } from '../models/User';
import { IAlertMessage } from '../models/AlertMessage';
import { ITransaction } from '../models/Transactions';
import { IOrderStatus } from '../models/Order';
import { Observable } from 'rxjs';
import { IGenerateQrResponse, ISuccessLogin } from '../models/Auth';
import { RegisterRequest } from '../models/Whitelabel';
import { IMinOperation, IPublicWithdraw } from '../../public/services/utils/public-data';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HttpClient) {}

  getTokens(): { jwtToken: string; refreshToken: string } {
    const tokens = { jwtToken: '', refreshToken: '' };
    tokens.jwtToken = localStorage.getItem('JWT_TOKEN');
    tokens.refreshToken = localStorage.getItem('REFRESH_TOKEN');
    return tokens;
  }

  //////////////////////////////////////////////////////////
  /////////////////// ---- TC APIs ---- /////////////////////

  register(email: string, password: string, ref: string = '', prodeReferredBy: string = '') {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post<User>(
        environment.tcApiUrl + 'user',
        { email, password, ref, prodeReferredBy },
        { headers },
      )
      .pipe(share());
  }

  registerWorldCoin(data: RegisterRequest) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (data.address) data.address = data.address.toLocaleLowerCase();
    if (!data.exchangeCountry) data.exchangeCountry = 'ARG';
    if (!data.address) data.address = data.userId;

    return this.http
      .post<{ accessToken: string; refreshToken: string }>(
        environment.tcApiUrl + 'user/whitelabel',
        data,
        { headers },
      )
      .pipe(share());
  }

  crossLogin(humanToken: string): Observable<IGenerateQrResponse> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().append('humanToken', humanToken);

    return this.http.get<IGenerateQrResponse>(environment.tcApiUrl + 'auth/crossLogin', {
      headers,
      params,
    });
  }

  login(email: string, password: string, fingerprint: string, token: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const obs = this.http
      .post<{
        accessToken: string;
        refreshToken: string;
        user: User;
        complianceChecks: IComplianceChecks;
      }>(
        environment.tcApiUrl + 'auth/login',
        { email, password, fingerprint, humanToken: token },
        { ...headers, observe: 'response' },
      )
      .pipe(share());
    return obs;
  }

  getMe() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.get<any>(environment.tcApiUrl + 'user', { headers }).pipe(share());
  }

  updateUserInfo(fields: IUserInfoField, updatePersonalDataLastCheckFlag: boolean = false) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .patch<any>(
        environment.tcApiUrl + 'user',
        { fields, updatePersonalDataLastCheckFlag },
        { headers },
      )
      .pipe(share());
  }

  updateUserWork(desc: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<void>(environment.tcApiUrl + 'user/compliance/work', { desc }, { headers })
      .pipe(share());
  }

  createMarketOrder(amount: string, coin: string, operation: string, code: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'order',
        {
          amount,
          coin,
          operation, // ['BUY' | 'SELL']
          code,
        },
        { headers },
      )
      .pipe(share());
  }

  getAlertMessage() {
    return this.http.get<IAlertMessage>(environment.tcApiUrl + 'settings/alerts');
  }

  getPromotionMessages() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get(environment.tcApiUrl + 'settings/promotions?platform=2', { headers });
  }

  lockMarketOrder(coin: string, operation: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'order/lock',
        {
          coin,
          operation, // ['BUY' | 'SELL']
        },
        { headers },
      )
      .pipe(share());
  }

  getAllPrices() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(environment.tcApiUrl + 'price/all', { headers }).pipe(share());
  }

  getPriceByCoin(coin: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.tcApiUrl + 'price/coin/' + coin, { headers })
      .pipe(share());
  }

  getUserLimits() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.get<any>(environment.tcApiUrl + `user/limits`, { headers }).pipe(share());
  }

  getFiatStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IFiatStatus[]>(environment.tcApiUrl + `settings/fiat/status`, { headers })
      .pipe(share());
  }

  updateUserPepInfo(pepData: IUserPepData) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<void>(
        environment.tcApiUrl + 'user/pep',
        {
          pepData,
        },
        { headers },
      )
      .pipe(share());
  }

  // /user/limits

  /////////////////// ---- END TC APIs ---- /////////////////
  //////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  //////////////// ---- BANK / ACCOUNT ---- ////////////////

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    accountType?: string,
    failedWithdrawId?: string,
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'user/bankAccount/' + currency,
        {
          cbu,
          description,
          bankCode,
          accountType,
          failedWithdrawId,
        },
        { headers },
      )
      .pipe(share());
  }

  /////////////// ---- BANK / ACCOUNT END ---- //////////////
  //////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////
  //////////////// ---- ONBOARDING / ACCOUNT ---- /////////////

  getUploadImageURL(
    docType: DOCUMENT_TYPE,
    fileName: string,
    deviceType: DEVICE_TYPE,
    isArbitrage = false,
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<{ url: string }>(
        environment.tcApiUrl + 'documentation/uploadUrl',
        { docType, fileName, deviceType, isArbitrage },
        { headers },
      )
      .pipe(share());
  }

  uploadImageToUrl(url: string, image: any): any {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data; boundary=something',
    });
    const formData: FormData = new FormData();
    formData.append('image', image);

    return this.http.put<any>(url, image, { headers }).pipe(share());
  }

  //////////////// ---- ONBOARDING / ACCOUNT END---- /////////////
  /////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  /////////////////// ---- CRYPTO ---- ////////////////////

  sendWithdrawEmailVerification(params: {
    address: string;
    amount: string;
    coin: string;
    chain: string;
    costCode: string;
  }) {
    const { address, costCode, amount, coin, chain } = params;
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'withdraw/pre',
        {
          address,
          amount,
          coin,
          chain,
          costCode,
        },
        { headers },
      )
      .pipe(share());
  }

  withdrawCrypto(code: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<ITransaction>(
        environment.tcApiUrl + 'withdraw',
        {
          code,
        },
        { headers },
      )
      .pipe(share());
  }

  getWithdrawCost(ticker: string, wallet: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.tcApiUrl + `withdraw/estimate?coin=${ticker}&address=${wallet}`, {
        headers,
      })
      .pipe(share());
  }

  getBlockchainStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IBlockchainStatus[]>(environment.tcApiUrl + `settings/crypto/status`, { headers })
      .pipe(share());
  }

  getOrdersStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IOrderStatus>(environment.tcApiUrl + `settings/order/status`, { headers })
      .pipe(share());
  }

  ///////////////// ---- CRYPTO END ---- ///////////////////
  /////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  ///////////////////// ---- AUTH ---- ////////////////////

  getPersona(passport: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('authorization', 'JWT SoyPlunzoPapa');
    return this.http
      .get<any>('https://api.tiendadolar.com.ar/v2/user/getPersona/' + passport, { headers })
      .pipe(share());
  }

  checkExistUser(repCuit: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<any>(
        environment.tcApiUrl + 'user/rep',
        {
          repCuit,
        },
        { headers },
      )
      .pipe(share());
  }

  ///////////////////// ---- AUTH END---- //////////////////////
  /////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  ///////////////////// ---- 2FA ---- //////////////////////

  show2FAQr() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.post<any>(environment.tcApiUrl + 'auth/2FA', {}, { headers }).pipe(share());
  }

  verifyActivation2FA(token: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + 'auth/verify2FA', { token }, { headers })
      .pipe(share());
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post(environment.tcApiUrl + apiUrl, { token }, { headers, observe: 'response' })
      .pipe(share());
  }

  verifyDeactivation2FA(token: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);

    return this.http
      .delete<any>(environment.tcApiUrl + `auth/2FA?token=${token}`, { headers })
      .pipe(share());
  }

  isMarketClosed() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.tcApiUrl + 'settings/closed', { headers })
      .pipe(map((res) => res.closed))
      .pipe(share());
  }

  sendTikTokEvent(body) {
    const headers = new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Access-Token', this.getTokens().jwtToken);
    return this.http.post<any>(
      environment.tcApiUrl + 'tiktok/track',
      {
        ...body,
      },
      {
        headers,
      },
    );
  }

  lockPriceWhitelabel(
    coin: 'USDC' | 'WLD' | 'USDT',
    amount: number,
    lockType: 'ADDRESS' | 'CUIT',
    against: ACCOUNT_CURRENCY = ACCOUNT_CURRENCY.ARS,
    chain: number,
    payload: { externalId: string },
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<{ price: number; expires: string; totalFee: number; networkFee?: number }>(
        environment.tcApiUrl + `order/lock/whitelabel`,
        {
          lockType,
          coin: coin + '_' + against,
          amount: +amount,
          chain: chain,
          payload,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  getTransaction(hash: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<IPublicWithdraw>(environment.tcApiUrl + `withdraw/${hash}/public`, { headers })
      .pipe(share());
  }

  getPublicWithdraw(legalId: string, wallet: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<IPublicWithdraw>(
        environment.apiManteca + `public/withdraw?legalId=${legalId}&address=${wallet}`,
        { headers },
      )
      .pipe(share());
  }

  getJwtWithUuid(uuid: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<{ token: string }>(environment.tcApiUrl + `auth/token/` + uuid, { headers })
      .pipe(share());
  }

  getMinOperation(pair: string, companyId: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IMinOperation>(environment.apiManteca + `public/company/${companyId}/${pair}/limits`, {
        headers,
      })
      .pipe(share());
  }
}
