/* eslint-disable @typescript-eslint/naming-convention */
import { DEVICE_TYPE, DOCUMENT_STATUS, DOCUMENT_TYPE } from '../models/User';
import { IFiatDeposit, IFiatTransaction } from '../models/FiatTransaction';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiService } from '../services/api.service';
import { ICryptoPortfolio, IFiatStatus } from '../models/Crypto';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { AuthSocketService } from '../services/auth-socket.service';
import { IPromotionMessage } from '../models/PromotionMessage';
import { map } from 'rxjs/operators';
import { ISuccessLogin } from '../models/Auth';
import { OnboardingState } from '../models/Onboarding';
import {
  AlertDialogComponent,
  AlertDialogData,
} from 'src/app/modules/shared/components/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private myCryptoPortfolio$ = new BehaviorSubject<ICryptoPortfolio[]>([]);
  private loggedUser$ = new BehaviorSubject<User>(undefined);
  private isMarketClosed$ = new BehaviorSubject<boolean>(undefined);
  private fiatStatus$ = new BehaviorSubject<IFiatStatus[]>([]);

  private _loggedUser: User;

  private onboardingState$ = new BehaviorSubject<OnboardingState>(undefined);
  private infoUserTerroristTruora$ = new BehaviorSubject<{ isTerrorist: boolean }>(undefined);

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private authSocketService: AuthSocketService,
  ) {
    const userLS = this.getUserFromLS();
    if (userLS) {
      this.loadUserFromLS();
    } else {
      this.getMe();
    }

    this.apiService.isMarketClosed().subscribe((value: boolean) => {
      this.isMarketClosed$.next(value);
    });

    this.loggedUser$.subscribe((user) => {
      if (user) {
        this.loadOnboardingDataFromLS();
      }
    });
  }

  get myCryptoPortfolio() {
    return this.myCryptoPortfolio$;
  }

  get loggedUserObs() {
    return this.loggedUser$;
  }

  get loggedUser() {
    return this._loggedUser;
  }

  set loggedUser(user) {
    this._loggedUser = user;
  }

  get isMarketClosed() {
    return this.isMarketClosed$;
  }
  get infoUserTerroristObs() {
    return this.infoUserTerroristTruora$;
  }

  getUserFromLS(): User | null {
    const userLS = localStorage.getItem('arugulaUserData');
    if (!userLS) return null;
    const user = JSON.parse(userLS);

    const requiredKeys = [
      '_id',
      'dnis',
      'flags',
      'bankAccounts',
      'security',
      'email',
      'cuit',
      'data',
      'phoneNumber',
      'name',
      'numberId',
      'searchName',
      'cryptoAddress',
      'cryptoAccounts',
      'fundsDocuments',
      'isCommerce',
      'balance',
      'work',
    ];

    const userLSKeys = Object.keys(user);
    const pathname = window.location.pathname;
    if (
      requiredKeys.some((requiredKey) => !userLSKeys.includes(requiredKey)) &&
      pathname.split('/')[2] !== 'signup'
    )
      return null;

    return user;
  }

  loadUserFromLS() {
    this._loggedUser = this.getUserFromLS();

    if (!localStorage.getItem('JWT_TOKEN')) {
      this.saveTokens({
        jwtToken: this._loggedUser.accessToken,
        refreshToken: this._loggedUser.refreshToken,
      });
    }
    this.loadOnboardingDataFromLS();
    this.loggedUser$.next(this._loggedUser);
  }

  loadOnboardingDataFromLS() {
    const onboardingData = localStorage.getItem('onboarding-data');
    if (onboardingData) {
      const data = JSON.parse(onboardingData);
      if (data.userId === this.loggedUser?.whitelabelId) {
        this.onboardingState.next(data);
      }
    }
  }

  get onboardingState() {
    return this.onboardingState$;
  }

  patchOnboardingState(data: OnboardingState) {
    this.onboardingState.next({ ...this.onboardingState.value, ...data });
  }

  sendTikTokPageView(url: string) {
    let body = {
      event: 'ViewContent',
      event_id: `${Date.now()}`,
      timestamp: new Date().toISOString(),
      context: {
        page: {
          url: url,
        },
        user: {
          email: this.loggedUser?.email ? sha256(this.loggedUser.email) : '',
        },
        ip: '190.190.27.177',
        user_agent: window.navigator.userAgent,
      },
    };
    if (!environment.production) {
      body['test_event_code'] = 'TEST01719';
    }
    return this.apiService.sendTikTokEvent(body);
  }

  sendTikTokEvent(
    url: string,
    quantity: number,
    currency: string,
    value: number,
    price: number,
    description: string,
  ) {
    let body = {
      event: 'CompletePayment',
      event_id: `${Date.now()}`,
      timestamp: new Date().toISOString(),
      context: {
        page: {
          url: url,
        },
        user: {
          ip: '190.190.27.177',
          user_agent: window.navigator.userAgent,
          email: sha256(this.loggedUser.email),
        },
      },
      properties: {
        contents: [
          {
            price: price,
            quantity: quantity,
            content_type: 'poduct',
          },
        ],
        value: value,
        description: description,
      },
    };
    if (currency !== '') {
      body.properties['currency'] = currency;
    }
    if (!environment.production) {
      body['test_event_code'] = 'TEST01719';
    }
    return this.apiService.sendTikTokEvent(body);
  }

  getMe() {
    if (!this.getTokens().jwtToken) {
      this.loggedUser = undefined;
      return;
    }
    this.apiService.getMe().subscribe(
      (user) => {
        if (!user.cryptoAccounts) user.cryptoAccounts = [];

        if (user.balance && !user.balance?.bundle) {
          user.balance.bundle = {
            ARS: {},
            USD: {},
          };
        }

        if (!user.fundsDocuments) {
          user.fundsDocuments = {
            funds: DOCUMENT_STATUS.NOT_UPLOADED,
            certificacionContable: DOCUMENT_STATUS.NOT_UPLOADED,
            impuestoCedular: DOCUMENT_STATUS.NOT_UPLOADED,
          };
        }

        this.loggedUser = user;

        if (!this.loggedUser.cuit) this.loggedUser.cuit = '';
        if (!this.loggedUser.security) this.loggedUser.security.twoFA = { status: 1 };

        this.loggedUser.activities = [];
        this.saveUserToLocalStorage();
        this.loggedUser$.next(user);
      },
      (err: HttpErrorResponse) => {
        this.loggedUser = undefined;
        console.log('error getMe ', err);
        if (
          (err.status === 404 || err.status === 401) &&
          (err.error === 'User not found.' || err.error.error === 'Expired token.')
        ) {
          this.logoutWLD();
        }
      },
    );
  }

  saveTokens(tokens: { jwtToken?: string; refreshToken?: string }) {
    if (tokens.jwtToken) {
      localStorage.setItem('JWT_TOKEN', tokens.jwtToken);
    }
    if (tokens.refreshToken) {
      localStorage.setItem('REFRESH_TOKEN', tokens.refreshToken);
    }
  }

  getTokens(): { jwtToken: string; refreshToken: string } {
    const tokens = { jwtToken: '', refreshToken: '' };
    tokens.jwtToken = localStorage.getItem('JWT_TOKEN');
    tokens.refreshToken = localStorage.getItem('REFRESH_TOKEN');
    return tokens;
  }

  saveUserToLocalStorage() {
    localStorage.setItem('arugulaUserData', JSON.stringify(this.loggedUser));
  }

  logoutWLD() {
    this.authSocketService.disconnect();
    this.myCryptoPortfolio.next([]);
    localStorage.removeItem('arugulaUserData');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('onboarding-data');
    this.loggedUser = undefined;
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    const obs = this.apiService.verifyLogin2FA(apiUrl, token);
    return obs;
  }

  getPersona(passport: string) {
    return this.apiService.getPersona(passport);
  }

  getPromotionMessages() {
    return this.apiService.getPromotionMessages().pipe(
      map((response: { message: IPromotionMessage[] }) => {
        return response.message || [];
      }),
    );
  }

  getAccounts(coin) {
    return this.loggedUser?.bankAccounts[coin === 1 ? 'ARS' : 'USD'];
  }

  getCryptoAccounts(chain: number) {
    return this.loggedUser?.cryptoAccounts?.filter((account) => account.chain === chain);
  }

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    accountType?: string,
    failedWithdrawId?: string,
  ) {
    return this.apiService.addAccount(
      currency,
      cbu,
      description,
      bankCode,
      accountType,
      failedWithdrawId,
    );
  }

  hasValidatedDNIS() {
    return this.loggedUser.flags.validatedDocuments;
  }

  getUploadImageUrl(
    type: DOCUMENT_TYPE,
    fileName: string,
    deviceType: DEVICE_TYPE,
    isArbitrage = false,
  ) {
    return this.apiService.getUploadImageURL(type, fileName, deviceType, isArbitrage);
  }

  uploadImageToUrl(url: string, image: any): any {
    return this.apiService.uploadImageToUrl(url, image);
  }

  getUserLimits() {
    const obs = this.apiService.getUserLimits();
    obs.subscribe((data) => {
      // console.log(data);
    });
    return obs;
  }

  refundWithdrawBalance(withdraw: IFiatTransaction) {
    this.loggedUser.balance.fiat[withdraw.coin] =
      +this.loggedUser.balance.fiat[withdraw.coin] + +withdraw.amount;
  }

  addDepositTxToHistory(deposit: IFiatDeposit) {
    this.loggedUser.balance.fiat[deposit.coin] =
      +this.loggedUser.balance.fiat[deposit.coin] + +deposit.amount;
    this.loggedUser$.next(this.loggedUser);
    this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
      panelClass: 'modal-container',
      disableClose: true,
      data: {
        title: 'Depósito realizado',
        content: 'Ya tenés disponible tu depósito para comenzar a operar.',
      },
    });
  }

  setDocumentStatus(documentType: DOCUMENT_TYPE, status: DOCUMENT_STATUS) {
    if (this.loggedUser.data.documentation) {
      this.loggedUser.data.documentation[documentType] = status;
    } else if (documentType === DOCUMENT_TYPE.PEP_DDJJ) {
      this.loggedUser.data.pepDoc = status;
    } else if (documentType === DOCUMENT_TYPE.SUJETO_OBLIGADO_DDJJ) {
      this.loggedUser.data.uifDoc = status;
    } else {
      this.loggedUser.data.documentation = {
        [documentType]: status,
      };
    }
    this.loggedUser$.next(this.loggedUser);
    this.saveUserToLocalStorage();
  }

  allCompanyDocumentsUpdated() {
    if (!this.loggedUser.data.documentation) {
      return false;
    }
    const neededDocs = [
      DOCUMENT_TYPE.BALANCE,
      DOCUMENT_TYPE.ESTATUTO,
      DOCUMENT_TYPE.IIBB,
      DOCUMENT_TYPE.ULTIMO_BALANCE_CERTIFICADO,
      DOCUMENT_TYPE.ACTA_DESIGNACION,
      DOCUMENT_TYPE.CONSTANCIA_DE_CUIT,
    ];
    // Si hay alguna doc que no exista o que tiene status NOT_UPLOADED (0), retorna false;
    for (let i = 0; i < neededDocs.length; i++) {
      if (
        !this.loggedUser.data.documentation[neededDocs[i]] ||
        this.loggedUser.data.documentation[neededDocs[i]] === 0 ||
        this.loggedUser.data.documentation[neededDocs[i]] === 2
      ) {
        return false;
      }
    }
    return true;
  }

  isCompany() {
    return (
      this.loggedUser?.cuit &&
      (this.loggedUser?.cuit?.startsWith('30') ||
        this.loggedUser?.cuit?.startsWith('33') ||
        this.loggedUser?.cuit?.startsWith('34'))
    );
  }

  private parseFloatStringToCurrency(numberStr: string): string {
    if (!numberStr) {
      return '0';
    }
    const arr = parseFloat(numberStr).toFixed(2).split('.');
    arr[0] = arr[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .join('.')
      .split('')
      .reverse()
      .join('');
    if (arr[1] === '00') {
      return arr[0];
    } else {
      return arr.join(',');
    }
  }

  getFiatStatus() {
    return this.fiatStatus$;
  }

  refreshFiatStatus() {
    this.apiService.getFiatStatus().subscribe((res) => {
      this.fiatStatus$.next(res);
    });
  }

  getJwtWithUuid(uuid: string) {
    return this.apiService.getJwtWithUuid(uuid);
  }
}
