import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss'],
})
export class WhatsappChatComponent implements OnInit {
  @Input() visibility: boolean;
  constructor() {}

  ngOnInit(): void {}
}
