<div class="container">
  <!-- <img *ngIf="data.tick" class="tick" src="/assets/icons/tick.svg" alt="success_logo" /> -->

  <div class="header">
    <img *ngIf="data.isError" src="assets/icons/alert-dialog-info.svg" alt="info" />
    <h1 [class.error]="data.isError">{{ data.title }}</h1>
  </div>

  <p class="content" *ngIf="data.content" [innerHtml]="data.content"></p>

  <div class="buttons">
    <button appButton="primary" [class.error]="data.isError" (click)="dialogRef.close()">
      {{ data.acceptLabel ?? 'Aceptar' }}
    </button>
  </div>

  <div class="error-code" *ngIf="data.errorCode">
    <p>{{ data.errorCode }}</p>
  </div>
</div>
