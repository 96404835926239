import { ReminderMessageComponent } from './components/reminder-message/reminder-message.component';
import { FormatCoinAmountPipe } from './pipes/format-coin-amount.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WrongDniDialogComponent } from './components/dialogs/wrong-dni-dialog/wrong-dni-dialog.component';
import { CUITPipe } from './pipes/cuit.pipe';
import { IvaPipe } from './pipes/iva.pipe';
import { CBUAliasPipe } from './pipes/cbualias.pipe';
import { AcceptDDJJComponent } from './components/dialogs/accept-ddjj/accept-ddjj.component';
import { CurrencyArsPipe } from './pipes/currency-ars.pipe';
import { TycComponent } from './components/dialogs/tyc/tyc.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { WhatsappChatComponent } from './components/whatsapp-chat/whatsapp-chat.component';
import { HistoryStatusPipe } from './pipes/history-status.pipe';
import { VolumeDayPipe } from './pipes/volume-day.pipe';
import { NotDotDirective } from '../../utils/directives/not-dot.directive';
import { StaticSecondFactorComponent } from './components/static-factor-dialog/static-second-factor.component';
import { FrequencyTransformPipe } from './pipes/frequency-transform.pipe';
import { ArsUsdLabelPipe } from './pipes/ars-usd-label.pipe';
import { FilterCoinsSwapPipe } from './pipes/filter-coins-swap.pipe';
import { RouterModule } from '@angular/router';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { InputHintComponent } from './components/input-hint/input-hint.component';
import { ButtonDirective } from './directives/button.directive';
import { CheckboxDirective } from './directives/checkbox.directive';
import { FormFieldDirective } from './directives/form-field.directive';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { OnboardingFooterComponent } from './components/onboarding-footer/onboarding-footer.component';
import { OnboardingHeaderComponent } from './components/onboarding-header/onboarding-header.component';
import { DocumentSignatureDialogComponent } from './components/dialogs/document-signature-dialog/document-signature-dialog.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { OnboardingBottomSheetComponent } from './components/onboarding-bottom-sheet/onboarding-bottom-sheet.component';

@NgModule({
  declarations: [
    WrongDniDialogComponent,
    CUITPipe,
    CBUAliasPipe,
    IvaPipe,
    CurrencyArsPipe,
    FormatNumberPipe,
    FormatCoinAmountPipe,
    AcceptDDJJComponent,
    TycComponent,
    CallbackPipe,
    WhatsappChatComponent,
    HistoryStatusPipe,
    VolumeDayPipe,
    NotDotDirective,
    StaticSecondFactorComponent,
    FrequencyTransformPipe,
    ArsUsdLabelPipe,
    FilterCoinsSwapPipe,
    AlertDialogComponent,
    ReminderMessageComponent,
    InputHintComponent,
    ButtonDirective,
    CheckboxDirective,
    FormFieldDirective,
    ConfirmDialogComponent,
    OnboardingFooterComponent,
    OnboardingHeaderComponent,
    DocumentSignatureDialogComponent,
    OnboardingBottomSheetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forChild(),
    RouterModule,
    //Material
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
  ],
  exports: [
    WrongDniDialogComponent,
    CUITPipe,
    CBUAliasPipe,
    IvaPipe,
    CurrencyArsPipe,
    AcceptDDJJComponent,
    FormatNumberPipe,
    FormatCoinAmountPipe,
    CallbackPipe,
    WhatsappChatComponent,
    HistoryStatusPipe,
    VolumeDayPipe,
    NotDotDirective,
    StaticSecondFactorComponent,
    FrequencyTransformPipe,
    ArsUsdLabelPipe,
    ReminderMessageComponent,
    InputHintComponent,
    ButtonDirective,
    CheckboxDirective,
    FormFieldDirective,
    OnboardingFooterComponent,
    OnboardingHeaderComponent,
    //Material
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class SharedModule {}
