import { DEVICE_TYPE, DOCUMENT_STATUS, DOCUMENT_TYPE } from '../models/User';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/User';
import { UserStore } from '../state/user.store';
import { IFiatDeposit, IFiatTransaction } from '../models/FiatTransaction';
import { Observable } from 'rxjs';
import { ISuccessLogin } from '../models/Auth';
import { HttpResponse } from '@angular/common/http';
import { EXCHANGE_COUNTRY, OnboardingState } from '../models/Onboarding';
import {
  AlertDialogComponent,
  AlertDialogData,
} from 'src/app/modules/shared/components/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

enum DNI_TYPE {
  FRONT = 'front',
  BACK = 'back',
  BOTH = 'both',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public router: Router, private userState: UserStore, private dialog: MatDialog) {}

  // REFERRAL

  get loggedUserObs() {
    return this.userState.loggedUserObs;
  }

  get myCryptoPortfolio() {
    return this.userState.myCryptoPortfolio;
  }

  get isMarketClosed() {
    return this.userState.isMarketClosed;
  }

  get onboardingState() {
    const obs = this.userState.onboardingState;

    obs.subscribe((data) => {
      if (data) localStorage.setItem('onboarding-data', JSON.stringify(data));
    });

    return obs;
  }

  get infoUserTerroristObs() {
    return this.userState.infoUserTerroristObs;
  }

  patchOnboardingState(data: OnboardingState) {
    this.userState.patchOnboardingState(data);
  }

  sendTikTokPageView(url: string) {
    return this.userState.sendTikTokPageView(url);
  }

  sendTikTokEvent(
    url: string,
    quantity: number,
    currency: string,
    value: number,
    price: number,
    description: string,
  ) {
    return this.userState.sendTikTokEvent(url, quantity, currency, value, price, description);
  }

  getMe() {
    this.userState.getMe();
  }

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    bankType?: string,
    failedWithdrawId?: string,
  ) {
    const obs = this.userState.addAccount(
      currency,
      cbu,
      description,
      bankCode,
      bankType,
      failedWithdrawId,
    );
    obs.subscribe((res) => {
      this.loggedUser.bankAccounts[currency].push(res);
      this.userState.loggedUserObs.next(this.loggedUser);
    });
    return obs;
  }

  saveAccount(currency: string, cbu: string, description: string) {
    return this.userState.addAccount(currency, cbu, description);
  }

  saveTokens(tokens: { jwtToken?: string; refreshToken?: string }) {
    this.userState.saveTokens(tokens);
  }

  getTokens(): { jwtToken: string; refreshToken: string } {
    return this.userState.getTokens();
  }

  saveUserToLocalStorage() {
    this.userState.saveUserToLocalStorage();
  }

  logoutWLD() {
    this.userState.logoutWLD();
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    return this.userState.verifyLogin2FA(apiUrl, token);
  }

  getPersona(passport: string) {
    return this.userState.getPersona(passport);
  }

  checkOnboardingStatus(type: string = '') {
    if (!this.hasValidatedDNIS()) {
      if (this.haveToUploadDNIS() || this.haveMissingData()) {
        this.dialog
          .open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
            panelClass: 'modal-container',
            data: {
              title:
                type === 'prode' ? 'Estás muy cerca de jugar' : 'Estás muy cerca de tus cryptos',
              content:
                type === 'prode'
                  ? `Para jugar es necesario que tu cuenta esté validada. Completá tu perfil haciendo click acá.`
                  : `Para operar es necesario que tu cuenta esté validada. Completá tu perfil haciendo click acá.`,
              acceptLabel: 'Completar datos',
            },
          })
          .afterClosed()
          .subscribe((data) => {
            if (data) {
              return this.router.navigate(['/auth', 'signup']);
            }
          });
        return false;
      } else if (this.hasPendingDNIS()) {
        this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
          panelClass: 'modal-container',
          data: {
            title: 'Perfil completado',
            content:
              'En este momento estamos validando tu cuenta. Te avisaremos por email cuando completemos tu registro.',
          },
        });
        return false;
      }
    }
    return true;
  }

  nextOnboardingStep(noNextStepCallback?: Function) {
    const user = this.userState.loggedUser;

    if (!user.data?.civilState) {
      this.router.navigate(['/auth', 'w-signup', 'personal-data']);
    } else if (this.hasToUpdateWork()) {
      this.router.navigate(['/auth', 'w-signup', 'data-extra']);
    } else if (this.hasToUploadPepDocuments()) {
      this.router.navigate(['/auth', 'w-signup', 'pep']);
    } else if (this.hasToUploadUIFDocuments()) {
      this.router.navigate(['/auth', 'w-signup', 'uif']);
    } else if (
      !user.bankAccounts.ARS?.length &&
      !user.bankAccounts.CLP?.length &&
      !user.bankAccounts.COP?.length
    ) {
      this.router.navigate(['/auth', 'w-signup', 'bank-info']);
    } else if (this.dniNotValidated() || this.hasToWaitValidation()) {
      this.router.navigate(['/auth', 'w-signup', 'id-photo']);
    } else {
      noNextStepCallback?.();
    }
  }

  //User Status related
  hasValidatedDNIS() {
    return (
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.VALIDATED &&
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.VALIDATED
    );
  }

  hasValidatedCompanyDocuments() {
    const docs = Object.values(this.loggedUser.data.documentation);
    return !docs.some((doc) => doc !== DOCUMENT_STATUS.VALIDATED);
  }

  hasValidatedDocuments() {
    if (this.isCompany()) {
      return this.hasValidatedCompanyDocuments();
    } else {
      return this.hasValidatedDNIS();
    }
  }

  hasPendingDNIS() {
    return (
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.PENDING ||
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.PENDING
    );
  }

  haveToUploadDNIS(type: string = DNI_TYPE.BOTH) {
    const needFront =
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.NOT_UPLOADED ||
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.REJECTED;
    const needBack =
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.NOT_UPLOADED ||
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.REJECTED;

    if (type === DNI_TYPE.FRONT) {
      return needFront;
    } else if (type === DNI_TYPE.BACK) {
      return needBack;
    } else if (type === DNI_TYPE.BOTH) {
      return needFront || needBack;
    }
  }

  dniNotValidated() {
    const needFront = this.loggedUser?.dnis?.front === DOCUMENT_STATUS.VALIDATED;
    const needBack = this.loggedUser?.dnis?.back === DOCUMENT_STATUS.VALIDATED;
    return !needFront || !needBack;
  }

  hasToUploadCompanyDocuments() {
    if (this.isCompany()) {
      const neededDocs = Object.values(this.loggedUser.data?.documentation || {});
      if (neededDocs.length !== 0) {
        return neededDocs.some(
          (doc) => doc !== DOCUMENT_STATUS.VALIDATED && doc !== DOCUMENT_STATUS.PENDING,
        );
      } else {
        return true;
      }
    }
    return false;
  }

  hasToWaitCompanyValidation() {
    if (this.isCompany()) {
      const neededDocs = Object.values(this.loggedUser.data?.documentation || {});
      if (neededDocs.length !== 0) {
        return neededDocs.every((doc) => doc === DOCUMENT_STATUS.PENDING);
      } else {
        return true;
      }
    }
    return false;
  }

  hasToUploadPepDocuments(): boolean {
    if (this.loggedUser?.data?.isPEP && this.loggedUser.exchangeCountry === EXCHANGE_COUNTRY.ARG) {
      return (
        this.loggedUser.data.pepDoc === undefined ||
        this.loggedUser.data.pepDoc === DOCUMENT_STATUS.NOT_UPLOADED ||
        this.loggedUser.data.pepDoc === DOCUMENT_STATUS.REJECTED
      );
    }
    return false;
  }

  hasToUploadUIFDocuments(): boolean {
    if (this.loggedUser?.data?.isUIF && this.loggedUser.exchangeCountry === EXCHANGE_COUNTRY.ARG) {
      return (
        this.loggedUser.data.uifDoc === undefined ||
        this.loggedUser.data.uifDoc === DOCUMENT_STATUS.NOT_UPLOADED ||
        this.loggedUser.data.uifDoc === DOCUMENT_STATUS.REJECTED
      );
    }
    return false;
  }

  hasToUpdateWork() {
    return (
      (!this.loggedUser?.work?.desc || this.loggedUser?.work?.desc === '-') && !this.isCompany()
    );
  }

  haveMissingData() {
    if (this.isCompany()) {
      return (
        !this.loggedUser ||
        !this.loggedUser.cuit ||
        !this.loggedUser.phoneNumber ||
        !this.loggedUser.data?.postalCode ||
        !this.loggedUser.bankAccounts.ARS.length
      );
    } else {
      return (
        !this.loggedUser ||
        !this.loggedUser.cuit ||
        !this.loggedUser.data?.birthDate ||
        !this.loggedUser.data?.civilState ||
        !this.loggedUser.phoneNumber ||
        !this.loggedUser.data?.postalCode ||
        !this.loggedUser.bankAccounts.ARS.length ||
        !this.loggedUser.work?.desc ||
        (this.loggedUser.work.desc === '-' && this.hasToUploadDocuments())
      );
    }
  }

  needToCompletedOnboarding() {
    if (this.isCompany()) {
      return (
        this.haveMissingData() || !this.allCompanyDocumentsUpdated() || this.hasToUploadDocuments()
      );
    } else {
      return (
        this.haveMissingData() || this.hasToUploadDocuments() || this.hasToUploadPepDocuments()
      );
    }
  }

  hasToUploadDocuments() {
    if (this.isCompany()) {
      return this.hasToUploadCompanyDocuments();
    } else {
      return this.haveToUploadDNIS();
    }
  }

  hasToWaitValidation() {
    if (this.isCompany()) {
      return this.hasToWaitCompanyValidation();
    } else {
      const dnis = this.loggedUser?.dnis;
      const pep = this.loggedUser?.data?.pepDoc;
      const uif = this.loggedUser?.data?.uifDoc;
      const statuses = [dnis?.back, dnis?.front, pep, uif];
      const somePending = statuses.some((doc) => doc === DOCUMENT_STATUS.PENDING);
      const hasToUpload = statuses.some(
        (doc) => doc === DOCUMENT_STATUS.REJECTED || doc === DOCUMENT_STATUS.NOT_UPLOADED,
      );

      return somePending && !hasToUpload;
    }
  }

  getUploadImageUrl(
    type: DOCUMENT_TYPE,
    fileName: string,
    deviceType: DEVICE_TYPE = DEVICE_TYPE.Web,
    isArbitrage = false,
  ) {
    return this.userState.getUploadImageUrl(type, fileName, deviceType, isArbitrage);
  }

  uploadImageToUrl(url: string, image: any): any {
    return this.userState.uploadImageToUrl(url, image);
  }

  getPromotionMessage() {
    return this.userState.getPromotionMessages();
  }

  // Accounts
  getAccounts(coin: number) {
    return this.userState.getAccounts(coin);
  }

  // Accounts
  getCryptoAccounts(coin: number) {
    return this.userState.getCryptoAccounts(coin);
  }

  isCompany() {
    return this.userState.isCompany();
  }

  addDepositTxToHistory(deposit: IFiatDeposit) {
    this.userState.addDepositTxToHistory(deposit);
  }

  refundWithdrawBalance(withdraw: IFiatTransaction) {
    this.userState.refundWithdrawBalance(withdraw);
  }

  //Limits
  getUserLimits() {
    return this.userState.getUserLimits();
  }

  setDocumentStatus(documentType: DOCUMENT_TYPE, status: DOCUMENT_STATUS) {
    this.userState.setDocumentStatus(documentType, status);
  }

  allCompanyDocumentsUpdated() {
    return this.userState.allCompanyDocumentsUpdated();
  }

  getFiatStatus() {
    return this.userState.getFiatStatus();
  }

  refreshFiatStatus() {
    return this.userState.refreshFiatStatus();
  }

  set loggedUser(user) {
    this.userState.loggedUser = user;
  }

  // Esto podría hacer referencia al store, asi logrando facade sin cambiar nada
  get loggedUser(): User {
    return this.userState.loggedUser;
  }

  getJwtWithUuid(uuid: string) {
    const obs = this.userState.getJwtWithUuid(uuid);
    obs.subscribe((resp) => {
      this.saveTokens({
        jwtToken: resp.token,
        refreshToken: resp.token,
      });
      this.getMe();
    });
    return obs;
  }

  updateCheckInfoTerrorist(value: { isTerrorist: boolean }) {
    this.userState.infoUserTerroristObs.next(value);
  }
}
