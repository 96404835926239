import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appButton]',
})
export class ButtonDirective implements OnChanges {
  @Input() appButton: 'primary' | 'secondary' | 'basic';

  constructor(private elementRef: ElementRef<HTMLButtonElement>) {
    this.elementRef.nativeElement.classList.add('manteca-btn');
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (!this.elementRef.nativeElement) return;

    if (simpleChanges.appButton.currentValue) {
      this.elementRef.nativeElement.classList.add(simpleChanges.appButton.currentValue);
    }
  }
}
