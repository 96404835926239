<!--The content below is only a placeholder and can be replaced.-->
<!-- <app-navbar></app-navbar> -->

<!-- <ngx-loading-bar></ngx-loading-bar> -->
<ngx-loading-bar
  [includeSpinner]="false"
  [fixed]="false"
  [height]="'3px'"
  [color]="'#ECB306'"
></ngx-loading-bar>

<div class="container">
  <router-outlet></router-outlet>
</div>
<app-whatsapp-chat [visibility]="showWhatsapp"></app-whatsapp-chat>
<!-- <app-footer ></app-footer> -->
<!-- <simple-notifications></simple-notifications> -->
